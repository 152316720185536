var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative"},[_c('icon',{ref:"toggleTableToolsBtn",class:_vm.settingsIconsOutline ? 'outline-default' : 'bg-transparent',attrs:{"name":"icons/gear","title":"Table settings"},on:{"click":function($event){return _vm.$emit('show', !_vm.toggleTableTools)}}}),_c('transition',{attrs:{"name":"slide"}},[(_vm.toggleTableTools)?_c('div',{directives:[{name:"closable",rawName:"v-closable",value:({
                exclude: ['toggleTableToolsBtn'],
                handler: 'onClose'
            }),expression:"{\n                exclude: ['toggleTableToolsBtn'],\n                handler: 'onClose'\n            }"}],staticClass:"absolute pl-2 pt-2 -mr-1 border rounded arrow-box dropdown w__320",class:[
                _vm.horizontalPosition === 'right' ? 'left-0 arrow-box_right' : 'right-0',
                _vm.verticalDirection === 'bottom' ? 'top-12' : 'bottom-12 arrow-box_top',
            ],staticStyle:{"z-index":"101"}},[_c('h5',{staticClass:"pb-2 pl-2 mr-2 text-body border-b flex flex__justify-between"},[_c('span',[_vm._v("Table settings")]),_c('button',{staticClass:"pr-2",on:{"click":function($event){return _vm.$emit('show', !_vm.toggleTableTools)}}},[_c('svg-importer',{attrs:{"icon-name":"icons/close"}})],1)]),_c('draggable',{staticClass:"dropdown_scroll list-group",attrs:{"handle":".handle"},on:{"update":function($event){return _vm.$emit('click')}},model:{value:(_vm.toggleableColumnsComputed),callback:function ($$v) {_vm.toggleableColumnsComputed=$$v},expression:"toggleableColumnsComputed"}},[_vm._l((_vm.toggleableColumnsComputed),function(col,cIndex){return [(_vm.isDisplayColumn(col))?_c('div',{key:cIndex,staticClass:"mr-2 pl-2 pr-2 border__bottom-grey_not-last"},[_c('label',{staticClass:"flex flex__justify-between items-center",staticStyle:{"height":"56px"}},[_c('span',{staticClass:"flex items-center cursor-pointer"},[_c('input',{staticClass:"form-checkbox",attrs:{"type":"checkbox","disabled":(cIndex < 3 && col.pin) || (_vm.disabledToggled && col.toggled)},domProps:{"checked":col.toggled},on:{"change":function($event){return _vm.$emit('update', col)}}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(col.title))])]),_c('span',{staticClass:"flex items-center"},[_c('button',{staticClass:"handle ml-5"},[_c('fa',{staticStyle:{"width":"20px","height":"20px"},attrs:{"icon":['fal','grip-lines']}})],1)])])]):_vm._e()]})],2)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }