<template>
    <div class="w-full">
        <v-debug v-if="debug" />

        <v-panel v-if="panel" :export="customExport"  :toolsRight="panelRight" :branding="branding" :selected="selected" @toggleTableTools="toggleTableTools = $event" :columns="columns" :settings-icons-outline="settingsIconsOutline">
            <template slot="panel">
                <slot name="panel"></slot>
            </template>

            <template slot="export">
                <slot name="export"></slot>
            </template>
        </v-panel>

        <v-tools v-if="tools" :export="customExport" :list="paginateList"  :toolsRight="toolsRight" :branding="branding">
            <template slot="tools">
                <slot name="tools"></slot>
            </template>
        </v-tools>


        <div class="block v-table bg-white border-radius overflow-hidden" :class="{ 'overflow-x-auto': !editable, 'whitespace-no-wrap': textWrappable }" :style="overflowMarginStyle">
            <div class="table-wrapper">
                <table class="table border-b table-fixed">
                    <thead>
                        <th class="w-12 text-base" v-if="checkable">
                            <input
                                type="checkbox"
                                class="form-checkbox"
                                v-model="table.allRowsChecked"
                                :indeterminate.prop="table.checkedRows.length > 0 && !table.allRowsChecked"
                                @change="toggleAllRows"
                            />
                        </th>
                        <th class="w-px p-0 text-base" v-if="hasDetail"></th>
                        <v-th
                            v-for="(col, cIndex) in table.toggledColumns"
                            :key="`${col.title}_${cIndex}`"
                            :export-key="col.key"
                            :sort-key="getSortKey(col)"
                            :class="[col.width, col.thClass, {'sticky': col.pin, 'z-10': col.pin, 'pl-1': col.pin}]"
                            :is-custom="customCols.includes(col.key)"
                            class="ok"
                        >
                            <slot name="header-cell" :col="col">
                                <span class="truncate">
                                    {{ col.title }}
                                </span>
                            </slot>
                        </v-th>
                    </thead>

                    <template v-if="!table.loading">
                        <VueDraggable
                            v-model="draggableRows"
                            class="dropdown_scroll"
                            handle=".handle"
                            tag="tbody"
                            @end="$emit('draggable-row-change', $event)"
                        >
                            <template v-for="(row, index) in table.visibleRows">
                                <tr
                                    :key="`${row.id}_${index}`"
                                    :class="{'cursor-pointer': !static, 'static': static, 'tr-error': erroredRows.includes(row.id)}"
                                    @mouseover="$emit('row-mouse-over', row)"
                                    @mouseleave="$emit('row-mouse-leave', row)"
                                >
                                    <td class="relative text-base align-middle text-body" @click.stop v-if="checkable">
                                        <div v-if="isSavedRow(row)" class="saved-row"></div>
                                        <div v-if="isErrorMarkVisible(row)" class="saved-row saved-row--error"></div>
                                        <input
                                            type="checkbox"
                                            v-model="table.checkedRows"
                                            :value="row[rowKey]"
                                            @click="$emit('check-row', row)"
                                            class="form-checkbox"
                                        />
                                    </td>
                                    <td class="pt-0 pb-0 pl-6 align-middle" v-if="hasDetail">
                                        <button
                                            class="flex items-center w-12 h-8 -ml-6"
                                            @click="toggleDetail(index)"
                                        >
                                            <fa
                                                :icon="['fal',currentDetail == index ? 'angle-down' : 'angle-right']"
                                                transform="grow-14"
                                                class="mx-auto"
                                            />
                                        </button>
                                    </td>
                                    <td
                                        v-for="(col, cIndex) in table.toggledColumns"
                                        :key="`${cIndex}_${index}`"
                                        :class="tdClasses(col, index)"
                                        :title="Array.isArray(row[col.key]) ? null : row[col.key]"
                                        @copy="copy = true"
                                        @click="rowClick(row, index, col)"
                                        @dblclick="rowDbClick(row, index, col)"
                                    >
                                        <slot name="cell" :row="row" :col="col" :index="index" />
                                    </td>
                                </tr>
                                <tr v-if="hasDetail && currentDetail == index" :key="`${row.id}_${index}_detail`">
                                    <td :colspan="table.columnsLength">
                                        <slot name="detail" :row="row" />
                                    </td>
                                </tr>
                            </template>
                            
                        </VueDraggable>
                    </template>

                    <v-loading v-if="table.loading" />
                </table>
            </div>

            <div class="table-bottom-wrapper">
                <v-pagination v-if="!paginate" />

                <v-paginate v-else :selected="selected" :toggleTableTools="toggleTableTools" />

                <slot v-if="table.total !== 0" name="bottom-tools"></slot>

                <slot v-if="table.total === 0" name="empty"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    import table from "./table";
    import Vue from "vue";
    import { VueDraggable } from 'vue-draggable-plus'

    export default {
        name: "VirtualTable",

        components: {
            VueDraggable
        },

        props: {
            id: {
                type: String
            },
            branding: {
                required: false,
                default: true,
                type: Boolean
            },
            rows: {
                type: Array,
                default: () => []
            },
            columns: {
                type: Array,
                default: () => []
            },
            queryParams: {
                type: Boolean,
                default: () => false
            },
            rowKey: {
                type: String,
                default: () => "id"
            },
            debug: {
                type: Boolean,
                default: () => false
            },
            endpoint: {
                type: String,
                default: () => null
            },
            exportEndpoint: {
                type: String,
                default: () => null
            },
            checkable: {
                type: Boolean,
                default: () => false
            },
            textWrappable: {
                type: Boolean,
                default: () => true
            },
            tools: {
                type: Boolean,
                default: () => true
            },
            toolsRight: {
                type: Boolean,
                default: () => true
            },
            panel: {
                type: Boolean,
                default: () => false
            },
            panelRight: {
                type: Boolean,
                default: () => true
            },
            responseProperty: {
                type: String,
                default: () => "data"
            },
            hasDetail: {
                type: Boolean,
                default: () => false
            },
            paginate: {
                type: Boolean,
                default: () => false
            },
            sortKey: {
                type: String,
                default: () => "updated_at"
            },
            sortOrder: {
                type: Number,
                default: () => -1
            },
            loading: {
                type: Boolean,
                default: () => false
            },
            filterParams: {
                type: Object,
                default: () => {}
            },
            noCache: {
                type: Boolean
            },
            onRowsLoaded: {
                type: Function,
                default: () => {}
            },
            onRowsPreloaded: {
                type: Function,
                default: () => {}
            },
            static: {
                type: Boolean,
                default: false,
                required: false,
            },
            savedRows: {
                type: Array,
                default: () => []
            },
            requiredFields: {
                type: Array,
                required: false,
                default: () => []
            },
            selected: {
                type: Number,
                default: 0,
            },
            customExport: {
                type: Function,
                default: null
            },
            exportable: {
                type: Boolean,
                default: true,
            },
            editable: {
                type: Boolean,
                default: false,
            },
            draggable: {
                type: Boolean,
                default: false,
            },
            paginateList: {
                type: Array,
                default: () => { return [10, 50, 100, 200, 500] },
            },
            paginateValue: {
                type: Number,
                default: null,
            },
            settingsIconsOutline: {
                type: Boolean,
                default: true,
            },
            erroredRows: {
                type: Array,
                default: () => [],
            },
            customCols: {
                type: Array,
                default: () => [],
            },
            onDraggableRowsSet: {
                type: Function,
                default: null,
            },
            savedData: {
                default: null,
            },
        },

        data: () => ({
            currentDetail: null,
            overflowMargin: 0,
            toggleTableTools: false,
            copy: false,
        }),

        computed: {
            total() {
                return this.table.total
            },
            overflowMarginStyle() {
                return `padding-bottom: ${this.overflowMargin}px; margin-bottom: -${this.overflowMargin}px;`;
            },
            draggableRows: {
                get() {
                    return this.table.rows;
                },
                set(value) {
                    if (!this.onDraggableRowsSet) {
                        return;
                    }

                    const updatedRows = this.onDraggableRowsSet(value);

                    if (!updatedRows) {
                        return;
                    }

                    this.table.rows = updatedRows;
                }
            },
        },

        watch: {
            total() {
                this.$emit('total', this.total)
            },
            loading(to, from) {
                this.table.loading = to;
            },
            rows(to, from) {
                this.table.loading = true;

                this.table.rows = this.rows;

                this.table.loading = false;
            },
            $route(to, from) {
                if (!to.query['filter[search]']) {
                    this.filterTerm = "";
                } else {
                    this.filterTerm = to.query['filter[search]'];
                }

                if (to.query && to.query.page) {
                    if (!this.table.rows || this.table.rows.length === 0 || this.queryParams) {
                        this.table.fetchRows();
                    }

                    this.table.syncState(to.query);

                    this.$emit('query-updated-again')
                }
            },
            filterParams(to, from) {
                if (JSON.stringify(to) === JSON.stringify(from)) {
                    return;
                }
                
                this.table.loading = true;

                this.table.goToPage(1);
                // this.table.filterParams = to;
                this.$set(this.table, 'filterParams', to);
                this.table.commitState();

                this.table.
                loading = false;
            },
            'table.rows'() {
                this.$emit('table-rows', this.table.rows);
            },
            'table.pageSize'() {
                this.$emit('updated-page-size', this.table.pageSize);
            },
            'table.page'() {
                this.$emit('updated-page', this.table.page);
            },
            'table.sendResponse'() {
                this.$emit('send-response', true)
            },
            'table.columns': {
                handler(to, from){
                    this.$emit('updated-columns', this.table.columns);
                },
                deep: true
            },
            'table.total'() {
                this.$emit('updated-total', this.table.total);
            },
            'table.sortKey'(val) {
                this.$emit('updated-sort-key', val);
            },
            'table.sortOrder'(val) {
                this.$emit('updated-sort-order', val);
            },
        },
        created() {
            if (this.paginate)  {
                this.table.pageSize = 50;
            }

            if (this.paginateValue) {
                this.table.pageSize = this.paginateValue || 10;
            }

            this.initTable();
            this.setData();
        },
        methods: {
            eventClick(event) {
                this.$emit('event-click', event);

                event.stopPropagation()
            },
            setOverflowMargin(value) {
                this.overflowMargin = value;
            },
            getSortKey(col) {
                if (! col.sortable) {
                    return null;
                }

                return col.sortable_key ? col.sortable_key : col.key;
            },
            rowClick(row, index, col) {
                if (col.preventClick) {
                    return;
                }

                this.$emit('row-click', { data: row, index: index})
            },
            rowDbClick(row, index, col) {
                if (col.preventClick) {
                    return;
                }

                this.$emit('row-dbclick', { data: row, index: index})
            },
            initTable() {
                this.table.id = this.id;
                this.table.query = this.query;
                this.table.rows = this.rows;
                this.table.queryParams = this.queryParams;
                this.table.endpoint = this.endpoint;
                this.table.exportEndpoint = this.exportEndpoint;
                this.table.checkable = this.checkable;
                this.table.responseProperty = this.responseProperty;
                this.table.hasDetail = this.hasDetail;
                this.table.sortKey = this.sortKey;
                this.table.sortOrder = parseInt(this.sortOrder);
                this.table.loading = this.loading;
                this.table.filterParams = this.filterParams;
                this.table.noCache = this.noCache;
                this.table.onRowsLoaded = this.onRowsLoaded;
                this.table.onRowsPreloaded = this.onRowsPreloaded;
                this.table.customExport = this.customExport;

                if (this.savedData?.table_settings?.length) {
                    this.table.columns = this.savedData.table_settings;
                    this.table.pageSize = +this.savedData.count_rows;
                    this.table.page = +this.savedData.pagination;

                    return;
                }

                this.table.setColumns(this.columns);
            },
            setData() {
                if (this.queryParams) {
                    this.table.router = this.$router;
                    this.table.route = this.$route;
                }

                this.table.rows = this.rows;

                if (this.queryParams && !this.endpoint) {
                    /*
                            If we want to base the table on the queryParams, and a page is set in the url
                        */
                    // Query params and page set
                    this.table.syncState(this.$route.query);
                    this.$emit('query-updated')

                    return;
                }

                if (this.queryParams && this.endpoint) {
                    /*
                            If we DO want to base the table on the queryParams, but use internal fethcing for rows
                        */
                    // query params with endpoint
                    this.table.commitState();

                    return;
                }

                if (!this.queryParams && this.endpoint) {
                    /*
                            If we DON'T want to base the table on the queryParams, but use internal fethcing for rows
                        */
                    // No query params but with endpoint
                    this.table.fetchRows();

                    return;
                }

                // None of the above
                this.table.commitState();
            },
            toggleAllRows() {
                if (!this.table.allRowsChecked) {
                    this.table.checkedRows = [];

                    this.$emit('check-row', 'uncheckAllRows')
                } else {
                    this.table.checkedRows = this.table.visibleRows.map(
                        row => row[this.rowKey]
                    );

                    this.$emit('check-row', 'checkAllRows')
                }
            },
            checkedRows() {
                return this.table.checkedRows
            },
            getRows() {
                return this.table.rows
            },
            refreshRows() {
                return this.table.commitState();
            },
            toggleDetail(rowIndex) {
                if (this.currentDetail == rowIndex) {
                    this.currentDetail = null;
                } else {
                    this.currentDetail = rowIndex;
                }
            },
            isSavedRow(row) {
                if (this.savedRows != null) {
                    if(this.savedRows.includes(row.consignment_no)) {
                        return true;
                    }
                }
            },
            isErrorMarkVisible(row) {
                for (let i = 0; i < this.requiredFields.length; ++i) {
                    const fieldName = this.requiredFields[i];
                    if (!row[fieldName]) {
                        return true;
                    }
                }
                return false;
            },
            tdClasses(col, index) {
                return [
                    {
                        'text-right pr-6': col.alignRight,
                        'overflow-visible-important': col.title === 'Sites' || col.key === 'consignments' || col.overflow,
                        'the-last': index > this.table.visibleRows.length - 5,
                        'sticky': col.pin,
                        'p-1': col.pin,
                        'truncate': col.truncate === undefined || col.truncate === true,
                        'align-middle': true
                    },
                    col.tdClass,
                ];
            }
        },
        beforeCreate() {
            this.table = new Vue(table);
        },
        provide() {
            return {
                table: this.table
            };
        },
    };
</script>

<style lang="scss" scoped>
.tr-error {
    background: rgba(235, 87, 87, .2);
    &:hover {
        background: rgba(235, 87, 87, .2);
    }
}

table td.sticky {
    position: sticky !important;
    left: auto;
}
</style>

<style lang="scss">
    @keyframes placeHolderShimmer {
        0% {
            background-position: -468px 0;
        }
        100% {
            background-position: 468px 0;
        }
    }
    .linear-background {
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: #f6f7f8;
        background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0.05) 8%,
            rgba(0, 0, 0, 0.07) 18%,
            rgba(0, 0, 0, 0.05) 33%
        );
        background-size: 1000px 104px;
        overflow: hidden;
    }

    .saved-row {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 4px;
        background-color: gray;
    }
</style>
